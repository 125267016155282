<template>
    <div>
        <!-- <table>
            <tbody>
                <tr class="parent-text">
                    {{
                        rowData[propertyName]
                    }}
                </tr>
                <tr
                    v-for="(sub, index) in rowData.subspesialisasi"
                    :key="index"
                >
                    <td class="child-text">
                        {{ sub[propertyName] }}
                    </td>
                </tr>
            </tbody>
        </table> -->
        <p class="parent-text">{{ rowData[propertyName] }}</p>
        <p
            class="child-text"
            v-for="(sub, index) in rowData.subspesialisasi"
            :key="index"
        >
            {{ sub[propertyName] }}
        </p>
    </div>
</template>

<script>
export default {
    name: "RowData",
    props: {
        rowData: {
            type: Object
        },
        propertyName: {
            type: String
        }
    }
};
</script>

<style></style>
