<template>
    <div>
        <PublishChips
            style="margin-bottom: 0.5rem;margin-left: 20px;"
            class="publish-table"
            :isPublished="rowData.status.isActive"
            :textString="rowData.status.text"
        >
        </PublishChips>
        <PublishChips
            style="margin-bottom: 0.5rem;"
            v-for="(sub, index) in rowData.subspesialisasi"
            :key="index"
            class="publish-table"
            :isPublished="sub.status.isActive"
            :textString="sub.status.text"
        >
        </PublishChips>
    </div>
</template>

<script>
import PublishChips from "../../_base/PublishChips.vue";
export default {
    components: {
        PublishChips
    },
    props: {
        rowData: {
            type: Object
        }
    }
};
</script>

<style></style>
