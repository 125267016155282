export default class PermissionClass {
    static isRenderByPermission(arrayPermission) {
        let permissionObject = {
            isCanCreateSpesialisasi: false,
            isCanEditSpesialisasi: false,
            isCanDeleteSpesialisasi: false,
            isCanViewDetailSpesialisasi: false,

            isCanCreateSubspesialisasi: false,
            isCanEditSubspesialisasi: false,
            isCanDeleteSubspesialisasi: false,
            isCanViewDetailSubspesialisasi: false
        };
        let permissionName = {
            isCanCreateSpesialisasi: "DoctorResource.POST.CreateSpesialisasi",
            isCanEditSpesialisasi: "DoctorResource.PUT.UpdateSpesialisasi",
            isCanDeleteSpesialisasi: "DoctorResource.DEL.DeleteSpesialisasi",
            isCanViewDetailSpesialisasi:
                "DoctorResource.View.SpesialisasiDetail",
            isCanCreateSubspesialisasi:
                "DoctorResource.POST.CreateSubspesialisasi",
            isCanEditSubspesialisasi:
                "DoctorResource.PUT.UpdateSubspesialisasi",
            isCanDeleteSubspesialisasi:
                "DoctorResource.DEL.DeleteSubspesialisasi",
            isCanViewDetailSubspesialisasi:
                "DoctorResource.View.SubspesialisasiDetail"
        };

        if (arrayPermission) {
            if (Array.isArray(arrayPermission)) {
                Object.keys(permissionName).forEach(key => {
                    if (arrayPermission.includes(permissionName[key])) {
                        permissionObject[key] = true;
                    }
                });
            }
        }

        return permissionObject;
    }
}
