<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 20V12M12 12V4M12 12H20M12 12H4"
            :stroke="strokeColor"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<style></style>
