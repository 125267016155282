var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"action-button"},[(
            _vm.permissionObject.isCanDeleteSpesialisasi ||
                _vm.permissionObject.isCanEditSpesialisasi
        )?_c('b-dropdown',{staticClass:"more-action",attrs:{"id":"dropdown-action","variant":"primary","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('EllipsisV',{attrs:{"strokeColor":'#23415B'}})]},proxy:true}],null,false,294561548)},[_vm._t("dropdown-item")],2):_vm._e(),(
            _vm.permissionObject.isCanViewDetailSpesialisasi ||
                (_vm.permissionObject.isCanViewDetailSubspesialisasi &&
                    !this.rowData.isChild)
        )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md button-action",staticStyle:{"margin-right":"15px"},attrs:{"title":"View"},on:{"click":function($event){$event.preventDefault();return _vm.editMethod(true)}}},[_c('EyeIcon',{staticStyle:{"width":"16px"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }