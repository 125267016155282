export default class SpesialisasiData {
    static getSpesialisasi(data) {
        if (data) {
            if (Array.isArray(data)) {
                const newData = [];
                data.forEach(item => {
                    newData.push({
                        id: item.ID,
                        namaIndonesia: item.namaIndo,
                        namaInggris: item.namaEng,
                        namaAwam: item.namaAwam,
                        namaAwamInggris: item.namaAwamEng,
                        status: {
                            text: item.isPublish ? "published" : "unpublished",
                            isActive: item.isPublish
                        },
                        isChild: false
                        // isPublish: item.isPublish
                        // subSpesialisasis: item.subSpesialisasis,
                    });
                    if (item.subSpesialisasis) {
                        item.subSpesialisasis.forEach(sub => {
                            newData.push({
                                id: sub.ID,
                                namaIndonesia: sub.namaIndo,
                                namaInggris: sub.namaEng,
                                namaAwam: sub.namaAwam,
                                namaAwamInggris: sub.namaAwamEng,
                                // isPublish: sub.isPublish
                                status: {
                                    text: sub.isPublish
                                        ? "published"
                                        : "unpublished",
                                    isActive: sub.isPublish
                                },
                                isChild: true
                            });
                        });
                    }
                });
                return newData;
            }
        }
    }
    static getSubDropdownData(data) {
        if (data) {
            if (Array.isArray(data)) {
                // const newData = [];
                // data.forEach(item => {
                //     newData.push({
                //         id: item.ID,
                //         namaIndonesia: item.namaIndo,
                //         namaInggris: item.namaEng,
                //         namaAwam: item.namaAwam,
                //         namaAwamInggris: item.namaAwamEng,
                //         isPublish: item.isPublish
                //     });
                // });
                // return newData;
                return data.map(item => {
                    return {
                        id: item.ID,
                        namaIndo: item.namaIndo,
                        namaEng: item.namaEng,
                        namaAwam: item.namaAwam,
                        namaAwamEng: item.namaAwamEng,
                        isPublish: item.isPublish
                    };
                });
            }
        }
    }
    static postSubspesialisasiData(data) {
        if (data) {
            return {
                namaIndo: data.namaIndonesia,
                namaEng: data.namaInggris,
                namaAwam: data.namaAwamIndonesia,
                namaAwamEng: data.namaAwamInggris,
                isPublish: data.isPublishProfile
                // subSpesialisasis: data.subspesialisasi
            };
        }
    }
    static postSubData(data, spesialisasi) {
        console.log(spesialisasi, "spesialisasi");
        if (data) {
            return {
                namaIndo: data.namaIndonesia,
                namaEng: data.namaInggris,
                namaAwam: data.namaAwamIndonesia,
                namaAwamEng: data.namaAwamInggris,
                isPublish: data.isPublishProfile,
                spesialisasi: {
                    ID: spesialisasi.id,
                    namaIndo: spesialisasi.namaIndonesia,
                    namaEng: spesialisasi.namaInggris,
                    namaAwam: spesialisasi.namaAwam,
                    namaAwamEng: spesialisasi.namaAwamInggris,
                    isPublish: spesialisasi.status.isActive
                }
            };
        }
    }
    static putSubspesialisasiData(data, ID) {
        if (data) {
            return {
                ID: ID,
                namaIndo: data.namaIndonesia,
                namaEng: data.namaInggris,
                namaAwam: data.namaAwamIndonesia,
                namaAwamEng: data.namaAwamInggris,
                isPublish: data.isPublishProfile,
                subSpesialisasis: data.subspesialisasi
            };
        }
    }
}
