<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.0002 4.99999C10.9206 4.99999 11.6668 4.2538 11.6668 3.33332C11.6668 2.41285 10.9206 1.66666 10.0002 1.66666C9.07969 1.66666 8.3335 2.41285 8.3335 3.33332C8.3335 4.2538 9.07969 4.99999 10.0002 4.99999Z"
            :fill="strokeColor"
        />
        <path
            d="M10.0002 11.6667C10.9206 11.6667 11.6668 10.9205 11.6668 10C11.6668 9.07957 10.9206 8.33337 10.0002 8.33337C9.07969 8.33337 8.3335 9.07957 8.3335 10C8.3335 10.9205 9.07969 11.6667 10.0002 11.6667Z"
            :fill="strokeColor"
        />
        <path
            d="M10.0002 18.3334C10.9206 18.3334 11.6668 17.5872 11.6668 16.6667C11.6668 15.7462 10.9206 15 10.0002 15C9.07969 15 8.3335 15.7462 8.3335 16.6667C8.3335 17.5872 9.07969 18.3334 10.0002 18.3334Z"
            :fill="strokeColor"
        />
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415b"
        }
    }
};
</script>

<style></style>
