<template>
    <b-dropdown-item class="elipsis-item center-item" @click.prevent="editEmit">
        <div style="width: 22px;">
            <slot name="icon"><EditIcon /> </slot>
        </div>
        <p>
            {{ buttonText }}
        </p>
    </b-dropdown-item>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
export default {
    components: {
        EditIcon
    },
    methods: {
        editEmit() {
            this.$emit("edit-data");
        }
    },
    props: {
        buttonText: {
            type: String,
            default: `Edit Spesialisasi`
        }
    }
};
</script>

<style></style>
