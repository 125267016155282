<template>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
        <div class="kt-portlet kt-portlet--mobile head-container-kt">
            <div
                style="border-bottom: unset;padding: 20px 20px 0 20px;"
                class="kt-portlet__head kt-portlet__head--lg header-container"
            >
                <base-title
                    style="width: 100%;"
                    class="head-label"
                    :head-title="headTitle"
                ></base-title>
                <div
                    class="kt-portlet__head-toolbar"
                    style="width: 100%;justify-content: flex-end;"
                >
                    <button
                        :disabled="isSyncDataLoading || isFetching"
                        @click.prevent="syncData"
                        type="button"
                        class="refresh-button sync-button"
                    >
                        <div>
                            <SyncIcon
                                :strokeColor="
                                    isSyncDataLoading || isFetching
                                        ? '#c1c1c1'
                                        : '#23415B'
                                "
                            ></SyncIcon>
                        </div>
                    </button>
                    <button
                        :disabled="isFetching"
                        @click.prevent="fetch"
                        type="button"
                        class="refresh-button"
                    >
                        <div>
                            <RefreshIcon
                                :strokeColor="
                                    isFetching ? '#c1c1c1' : '#23415B'
                                "
                                style="width:14px;"
                            ></RefreshIcon>
                        </div>
                    </button>
                    <button
                        v-if="permissionObject.isCanCreateSpesialisasi"
                        @click.prevent="spesialisasiAddModal"
                        type="button"
                        class="btn btn-primary custom-modal-button"
                    >
                        <p>
                            Tambah Spesialisasi
                        </p>
                        <i class="flaticon2-add-1"></i>
                    </button>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div
                    class="row"
                    style="margin-bottom: 2em;flex-direction: row-reverse;"
                >
                    <div>
                        <FilterButton
                            :activeFilter="activeFilter()"
                            :clickedMethod="openModalFilter"
                        >
                        </FilterButton>
                    </div>
                </div>
                <div
                    v-if="activeFilter()"
                    style="padding: 0 20px 0 20px;"
                    class="selected-filter"
                >
                    <FilterItems
                        :isFetching="isFetching"
                        :clickedMethod="deleteIndvFilter"
                        :filterList="filterItemData()"
                    ></FilterItems>

                    <a
                        v-if="!isFetching"
                        @click.prevent="deleteAllFilter"
                        style="cursor:pointer;"
                    >
                        Hapus Semua Filter</a
                    >
                </div>
                <div
                    class="record-limit"
                    style="display: flex;flex-direction: row-reverse;align-items: center;margin-bottom: 15px;"
                >
                    <select
                        @change="$refs.table.setLimit($event.target.value)"
                        name="limit"
                    >
                        <option
                            v-for="(value, index) in recordValues"
                            :key="index"
                            :value="value"
                            >{{ value }}</option
                        >
                    </select>
                    <label>Show Records :</label>
                </div>
                <div class="table-container">
                    <h3>Daftar Spesialisasi</h3>
                    <v-server-table
                        id="holiday-table"
                        :columns="columns"
                        :options="options"
                        name="spesialisasi_table"
                        ref="table"
                    >
                        <PublishChips
                            class="publish-table"
                            slot="status"
                            slot-scope="{ row }"
                            :isPublished="row.status.isActive"
                            :textString="row.status.text"
                        >
                        </PublishChips>
                        <BtnAction
                            v-if="
                                permissionObject.isCanDeleteSpesialisasi ||
                                    permissionObject.isCanEditSpesialisasi
                            "
                            style="justify-content: center;"
                            slot-scope="{ row }"
                            :rowData="row"
                            slot="action"
                            :buttonMethod="buttonMethod"
                            :permissionObject="permissionObject"
                        >
                            <OptionEdit
                                v-if="
                                    permissionObject.isCanCreateSubspesialisasi &&
                                        !row.isChild
                                "
                                slot="dropdown-item"
                                @edit-data="subspesialisasiAddData(row)"
                                :buttonText="`Tambah Subspesialisasi`"
                            >
                                <AddIcon
                                    style="height: 16px;width: 16px;"
                                    slot="icon"
                                >
                                </AddIcon>
                            </OptionEdit>
                            <OptionEdit
                                v-if="
                                    permissionObject.isCanEditSpesialisasi ||
                                        (permissionObject.isCanEditSubspesialisasi &&
                                            !row.isChild)
                                "
                                slot="dropdown-item"
                                @edit-data="
                                    spesialisasiEditModal(row.isChild, row)
                                "
                                :buttonText="
                                    row.isChild
                                        ? `Edit Subspesialisasi`
                                        : 'Edit Spesialisasi'
                                "
                            >
                            </OptionEdit>
                            <OptionDelete
                                v-if="
                                    permissionObject.isCanDeleteSpesialisasi ||
                                        (permissionObject.isCanDeleteSubspesialisasi &&
                                            !row.isChild)
                                "
                                slot="dropdown-item"
                                @delete-data="deleteMethod(row)"
                                :buttonText="
                                    row.isChild
                                        ? `Hapus Subspesialisasi`
                                        : 'Hapus Spesialisasi'
                                "
                            >
                            </OptionDelete>
                        </BtnAction>
                    </v-server-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../../_base/BaseTitle.vue";

import BtnAction from "../component/BtnAction.vue";

import FilterButton from "../../_base/FilterButton.vue";
import FilterItems from "../../_base/FilterItems.vue";
import SearchInput from "../../_base/SearchInput.vue";

import PublishChips from "../../_base/PublishChips.vue";
import SyncIcon from "../../_icons/SyncIcon.vue";
import RefreshIcon from "../../_icons/RefreshIcon.vue";
import ExternalLinkIcon from "../../_icons/ExternalLinkIcon.vue";
import AddIcon from "../../_icons/AddIcon.vue";

import OptionDelete from "../component/OptionDeleteButton.vue";
import OptionEdit from "../component/OptionEditButton.vue";

import DataRow from "../component/DataRow.vue";
import PublishData from "../component/PublishData.vue";
import BtnActionData from "../component/BtnActionData.vue";

export default {
    name: "SpesialisasiScreen",
    components: {
        BaseTitle,
        FilterButton,
        FilterItems,
        SearchInput,
        PublishChips,
        ExternalLinkIcon,
        BtnAction,
        RefreshIcon,
        OptionDelete,
        OptionEdit,
        DataRow,
        PublishData,
        BtnActionData,
        SyncIcon,
        AddIcon
    },
    props: {
        methodObject: { type: Object },
        buttonMethod: { type: Object },
        filterList: { type: Object },
        isFetching: { type: Boolean },
        permissionObject: {
            type: Object
        },
        isSyncDataLoading: { type: Boolean }
    },
    methods: {
        filterItemData() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.filterItemsDataMethod == "function"
                ) {
                    return this.methodObject.filterItemsDataMethod();
                }
            }
        },
        deleteIndvFilter(payload) {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteIndvFIlterMethod ==
                    "function"
                ) {
                    this.methodObject.deleteIndvFIlterMethod(payload);
                }
            }
        },
        activeFilter() {
            let activeNumber = 0;
            if (this.filterList) {
                Object.keys(this.filterList).forEach(key => {
                    if (key == "dateRange") {
                        if (this.filterList.dateRange) {
                            if (this.filterList.dateRange.start) {
                                activeNumber++;
                            }
                            if (this.filterList.dateRange.end) {
                                activeNumber++;
                            }
                        }
                    } else if (
                        key == "tipeTatapMuka" ||
                        key == "tipeTelekonsul"
                    ) {
                        if (
                            this.filterList[key] !== null ||
                            this.filterList[key] !== undefined
                        ) {
                            activeNumber++;
                        }
                    } else {
                        if (
                            this.filterList[key].length > 0 &&
                            typeof this.filterList[key] !== "string"
                        ) {
                            activeNumber += this.filterList[key].length;
                        } else if (typeof this.filterList[key] == "string") {
                            activeNumber++;
                        }
                    }
                });
                return activeNumber;
            }
        },
        serverTableInit() {
            if (this.methodObject) {
                if (this.methodObject.controllerReqFunctionMethod) {
                    this.options.requestFunction = this.methodObject.controllerReqFunctionMethod;
                }
                if (this.methodObject.controllerRespFunctionMethod) {
                    this.options.responseAdapter = this.methodObject.controllerRespFunctionMethod;
                }
            }
        },
        openModalFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.openModalFilterMethod == "function"
                ) {
                    this.methodObject.openModalFilterMethod();
                }
            }
        },
        spesialisasiAddModal() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.spesialisasiAddModalMethod ==
                    "function"
                ) {
                    this.methodObject.spesialisasiAddModalMethod();
                }
            }
        },
        spesialisasiEditModal(isChild, initData, isViewOnly) {
            if (this.buttonMethod) {
                // !rowData.isChild
                let strButton = `spesialisasiEditModalMethod`;
                if (isChild) {
                    strButton = `subSpesialisasiEditModalMethod`;
                }
                if (typeof this.buttonMethod[strButton] == "function") {
                    this.buttonMethod[strButton](initData, isViewOnly);
                }
            }
        },
        subspesialisasiAddData(row) {
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod.subspesialisasiAddModalMethod ==
                    "function"
                ) {
                    this.buttonMethod.subspesialisasiAddModalMethod(row);
                }
            }
        },
        deleteMethod(row) {
            if (this.buttonMethod) {
                if (
                    typeof this.buttonMethod
                        .deleteConfirmationSpesialisasiMethod == "function"
                ) {
                    this.buttonMethod.deleteConfirmationSpesialisasiMethod(row);
                }
            }
        },
        deleteAllFilter() {
            if (this.methodObject) {
                if (
                    typeof this.methodObject.deleteAllFilterMethod == "function"
                ) {
                    this.methodObject.deleteAllFilterMethod();
                }
            }
        },
        fetch() {
            if (this.methodObject) {
                if (typeof this.methodObject.getDataMethod == "function") {
                    this.methodObject.getDataMethod();
                }
            }
        },
        syncData() {
            if (this.methodObject) {
                if (typeof this.methodObject.syncDataMethod == "function") {
                    this.methodObject.syncDataMethod();
                }
            }
        }
    },
    data() {
        return {
            headTitle: "Master Spesialisasi",
            recordValues: [10, 25, 50, 100],
            // isFetching: false,
            columns: [
                "id",
                "namaIndonesia",
                "namaInggris",
                "namaAwam",
                "namaAwamInggris",
                "status",
                "action"
            ],
            options: {
                texts: {
                    filterBy: "Cari",
                    loadingError: "testing"
                },
                filterable: false,
                headings: {
                    namaIndonesia: "Nama ID",
                    namaAwam: "Nama Awam ID",
                    namaInggris: "Nama EN",
                    namaAwamInggris: "Nama Awam EN",
                    status: "Status",
                    action: "Actions",
                    id: "ID"
                },
                sortIcon: {
                    base: "fas",
                    is: "fa-sort",
                    up: "fa-sort-up",
                    down: "fa-sort-down"
                },
                sortable: ["namaIndonesia"],
                perPageValues: [],
                toMomentFormat: "YYYY-MM-DD",
                filterByColumn: false,
                requestFunction: null,
                responseAdapter: null,
                customFilters: ["filterQuery"],
                cellClasses: {
                    id: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    namaIndonesia: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    namaAwam: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    namaInggris: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    namaAwamInggris: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    status: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ],
                    action: [
                        {
                            class: "child-text",
                            condition: row => row.isChild
                        },
                        {
                            class: "parent-text",
                            condition: row => !row.isChild
                        }
                    ]
                }
                // templates: {
                //     jumlah(h, { jumlah }) {
                //         // return jumlah with indonesian currency format
                //         return `Rp. ${jumlah.toLocaleString("id-ID")}`;
                //     }
                // }
            }
        };
    },
    created() {
        this.serverTableInit();
    }
};
</script>

<style></style>
