<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2125_79753)">
            <path
                d="M14.5 0.5C13.9478 0.5 13.5 0.947187 13.5 1.5V3.81531C12.2531 2.07875 10.2312 1 8 1C5.04688 1 2.45594 2.88562 1.54938 5.69375C1.37944 6.21906 1.66803 6.78375 2.19344 6.9525C2.72125 7.12437 3.28344 6.83337 3.45281 6.30906C4.09062 4.32812 5.91875 3 8 3C9.57812 3 11.0081 3.76719 11.8875 5H10.5C9.94781 5 9.5 5.44719 9.5 6C9.5 6.55281 9.94781 7 10.5 7H14.5C15.0522 7 15.5 6.55281 15.5 6V1.5C15.5 0.947187 15.0531 0.5 14.5 0.5ZM13.8062 9.05C13.2775 8.88006 12.7163 9.16912 12.5469 9.69344C11.9094 11.6719 10.0813 13 8 13C6.42188 13 4.99219 12.2328 4.1125 11H5.5C6.05219 11 6.5 10.5528 6.5 10C6.5 9.44719 6.05219 9 5.5 9H1.5C0.947812 9 0.5 9.44719 0.5 10V14.5C0.5 15.0528 0.947812 15.5 1.5 15.5C2.05219 15.5 2.5 15.0528 2.5 14.5V12.1847C3.74688 13.9219 5.76875 15 7.97188 15C10.9234 15 13.5156 13.1144 14.4219 10.3062C14.6219 9.78125 14.3312 9.19063 13.8062 9.05Z"
                :fill="strokeColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_2125_79753">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: "#23415B"
        }
    }
};
</script>

<style></style>
