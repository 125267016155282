<template>
    <div class="action-button">
        <!-- <div v-if="!rowData.isChild"> -->
        <b-dropdown
            v-if="
                permissionObject.isCanDeleteSpesialisasi ||
                    permissionObject.isCanEditSpesialisasi
            "
            id="dropdown-action"
            variant="primary"
            dropleft
            class="more-action"
        >
            <template #button-content>
                <EllipsisV :strokeColor="'#23415B'"></EllipsisV>
            </template>
            <slot name="dropdown-item"></slot>
        </b-dropdown>
        <button
            v-if="
                permissionObject.isCanViewDetailSpesialisasi ||
                    (permissionObject.isCanViewDetailSubspesialisasi &&
                        !this.rowData.isChild)
            "
            style="margin-right: 15px;"
            @click.prevent="editMethod(true)"
            class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
            title="View"
        >
            <EyeIcon style="width:16px;" />
        </button>
        <!-- </div> -->

        <!-- <div
            v-else
            class="action-link"
            slot="dropdown-item"
            style="margin-bottom: 0.8rem;"
        >
            <a
                style="display: flex;align-items: center;"
                target="_blank"
                :href="baseUrl + subUrl + `?id=${rowData.id}`"
                >Lihat <ExternalLinkIcon style="margin-left: 8px;"
            /></a>
        </div> -->
    </div>
</template>

<script>
import EditIcon from "../../_icons/EditIcon.vue";
import EyeIcon from "../../_icons/EyeIcon.vue";
import TrashIcon from "../../_icons/TrashIcon.vue";
import ExternalLinkIcon from "../../_icons/ExternalLinkIcon.vue";

import EllipsisV from "../../_icons/EllipsisV.vue";
export default {
    components: {
        EditIcon,
        EyeIcon,
        TrashIcon,
        EllipsisV,
        ExternalLinkIcon
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        },
        permissionObject: {
            type: Object
        }
    },
    data() {
        return {
            baseUrl: window.location.origin,
            subUrl: `/master-subspesialisasi`
        };
    },
    methods: {
        editMethod(isView) {
            if (this.buttonMethod) {
                let strButton = `spesialisasiEditModalMethod`;
                if (this.rowData.isChild) {
                    strButton = `subSpesialisasiEditModalMethod`;
                }
                if (typeof this.buttonMethod[strButton] == "function") {
                    this.buttonMethod[strButton](this.rowData, isView);
                }
            }
        }
    }
};
</script>

<style></style>
