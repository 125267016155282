<template>
    <div>
        <base-header
            class="sticky-base-header"
            base-title="Master Spesialisasi"
        ></base-header>
        <SpesialisasiScreen
            :methodObject="methodObject"
            :buttonMethod="buttonMethod"
            :filterList="filterList"
            :isFetching="isFetching"
            :permissionObject="permissionObject"
            :isSyncDataLoading="isSyncDataLoading"
        >
        </SpesialisasiScreen>
    </div>
</template>

<script>
import { Event } from "vue-tables-2";
import BaseHeader from "../../_base/BaseHeader.vue";

import ModalService from "../../../services/modal.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const spesialisasiRepo = RepositoryFactory.get("spe");
import createRequestOption from "../../../repositories/RequestParamUtil";
import BlockUI from "../../_js/BlockUI";

import SpesialisasiFilterModal from "../component/spesialisasi-modal/SpesialisasiFilterModal.vue";
import SpesialisasiAddDataModal from "../component/spesialisasi-modal/SpesialisasiAddDataModal.vue";

import SpesialisasiScreen from "./SpesialisasiScreen.vue";

import DataClass from "../class/spesialisasi-data-class";
import PermissionClass from "../class/permission-class";

// import DangerImg from "../../_icons/DangerImg.png";

import SwalCustom from "../../_general/SwalCustom.vue";
const SweetAlert = Vue.extend(SwalCustom);

export default {
    name: "SpesialisasiController",
    components: {
        SpesialisasiScreen,
        BaseHeader
    },
    data() {
        var vx = this;
        return {
            blockUi: new BlockUI(),
            filterList: {},
            isFetching: false,
            // dangerImg: DangerImg,
            methodObject: {
                controllerReqFunctionMethod: vx.controllerReqFunction,
                controllerRespFunctionMethod: vx.controllerRespFunction,
                openModalFilterMethod: vx.openModalFilter,
                spesialisasiAddModalMethod: vx.spesialisasiAddModal,

                filterItemsDataMethod: vx.filterItemsData,
                deleteIndvFIlterMethod: vx.deleteIndvFIlter,
                deleteAllFilterMethod: vx.deleteAllFilter,
                getDataMethod: vx.emitFilterQuery,
                syncDataMethod: vx.syncData
            },
            buttonMethod: {
                deleteConfirmationSpesialisasiMethod:
                    vx.deleteConfirmationSpesialisasi,
                spesialisasiEditModalMethod: vx.spesialisasiEditModal,
                subspesialisasiAddModalMethod: vx.subspesialisasiAddModal,
                subSpesialisasiEditModalMethod: vx.subSpesialisasiEditModal
            },
            permissionObject: {},
            isSyncDataLoading: false
        };
    },
    watch: {
        filterList: {
            handler(val, oldVal) {
                this.$router.push(
                    {
                        name: "master-spesialisasi",
                        query: this.filterList
                    },
                    e => {
                        this.routeQueryToFilterList();
                        // this.emitFilterQuery();
                    }
                );
            },
            deep: true
        }
    },
    methods: {
        initPermissionCheck() {
            let arrayPermission = localStorage.getItem("permission").split(",");
            this.permissionObject = PermissionClass.isRenderByPermission(
                arrayPermission
            );
        },
        emitFilterQuery() {
            Event.$emit(
                "vue-tables.spesialisasi_table.filter::filterQuery",
                this.filterList
            );
        },
        deleteAllFilter() {
            this.filterList = {};
            this.emitFilterQuery();
        },
        deleteIndvFIlter(payload) {
            let { category, value } = payload;
            const asArray = Object.entries(this.filterList);

            const filtered = asArray.filter(([key, v]) => {
                return key != category || v != value;
            });

            const newFIlterList = Object.fromEntries(filtered);
            this.filterList = newFIlterList;
            // const unblock = this.blockUi.blockPage();
            this.emitFilterQuery();
            // unblock();
        },
        filterItemsData() {
            let payload = [];
            for (let key in this.filterList) {
                if (this.filterList[key] != null) {
                    payload.push({
                        category: key,
                        value: this.filterList[key]
                    });
                }
            }
            return payload;
        },
        showPopUp(message, title, icon) {
            let messageStr = message ? message : "";
            let titleStr = title ? title : "";
            let iconStr = icon ? icon : "";
            new SweetAlert().show(
                {
                    title: titleStr,
                    text: messageStr,
                    allowOutsideClick: true,
                    textConfirm: "Ok",
                    timer: 3000
                },
                iconStr
            );
        },
        showErrorPopUp(err) {
            let message = "Unknown Error Has Occurred";
            let title = "Error";

            if (err.message) {
                message = err.message;
            }
            if (err.error) {
                title = err.error;
            }
            new SweetAlert().show(
                {
                    title: title,
                    text: message,
                    allowOutsideClick: true,
                    reverseButtons: true,
                    showCancelButton: false,
                    textConfirm: "OK"
                },
                "dangerImg"
            );
        },
        controllerReqFunction(query) {
            let data = query;
            let params = {};
            params.page = data.page;
            params.page_size = data.limit;
            if (!_.isEmpty(this.filterList)) {
                data.filterQuery = this.filterList;
            }
            if (!data.filterQuery && !data.orderBy) {
                this.isFetching = true;
                return spesialisasiRepo
                    .getSpesialisasi({ params })
                    .catch(async err => {
                        console.log(err);
                        this.showErrorPopUp(err);
                    });
            } else {
                let requestParam = createRequestOption(params);
                let queryNames = {
                    id: "id",
                    namaIndonesia: "namaIndo",
                    namaInggris: "namaEng",
                    namaAwamIndonesia: "namaAwam",
                    namaAwamInggris: "namaAwamEng",
                    namaAwam: "namaAwam",
                    publishSpesialisasi: "isPublish"
                };
                let sortNames = {
                    namaIndonesia: "sort"
                };
                let isPublish = {
                    publish: true,
                    unpublish: false
                };
                if (data.orderBy) {
                    let order = data.ascending ? "asc" : "desc";
                    requestParam.params.append(sortNames[data.orderBy], order);
                }
                Object.keys(data.filterQuery).forEach(query => {
                    if (query != "publishSpesialisasi") {
                        if (data.filterQuery[query]) {
                            requestParam.params.append(
                                queryNames[query],
                                data.filterQuery[query]
                            );
                        }
                    } else if (query == "publishSpesialisasi") {
                        requestParam.params.append(
                            queryNames[query],
                            isPublish[data.filterQuery[query]]
                        );
                    }
                });
                this.isFetching = true;
                return spesialisasiRepo
                    .getSpesialisasi(requestParam)
                    .catch(async err => {
                        console.log(err, "controllerReqFunction error");
                        console.log(
                            err.message,
                            "controllerReqFunction error 119"
                        );
                        this.showErrorPopUp(err);
                    });
            }
        },
        async getSupsesialisasiDropdown(searchQuery) {
            try {
                let params = {
                    page_size: 20
                    // nama: searchQuery
                };
                if (searchQuery) {
                    params.namaIndo = searchQuery;
                    params.page_size = 1000;
                }
                const resp = await spesialisasiRepo.getSupsesialisasiDropdown(
                    params
                );
                if (resp.error) {
                    throw resp;
                }
                return DataClass.getSubDropdownData(resp);
            } catch (error) {
                console.log(error, "getSupsesialisasiDropdown");
                this.showErrorPopUp(error);
            }
        },
        controllerRespFunction(resp) {
            let classData = DataClass.getSpesialisasi(resp.data);
            this.isFetching = false;
            return {
                data: classData,
                count: resp.headers["x-count"]
            };
        },
        validateFilterData(data) {
            let payload = { ...data };
            Object.keys(payload).forEach(key => {
                if (Array.isArray(payload[key])) {
                    if (payload[key].length == 0) {
                        delete payload[key];
                    }
                } else if (!payload[key]) {
                    delete payload[key];
                }
            });
            return payload;
        },
        openModalFilter() {
            let closeModalFunc;
            let payload = {
                initialData: this.filterList,
                action: data => {
                    const validated = this.validateFilterData(data);
                    this.filterList = validated;
                    this.emitFilterQuery();
                    closeModalFunc();
                }
            };
            let { close } = ModalService.open(SpesialisasiFilterModal, payload);
            closeModalFunc = close;
        },
        async subspesialisasiAddModal(props) {
            let closeModalFunc;
            let payload = {
                modalTitle: "Tambah Subspesialisasi",
                idTitle: `Spesialisasi ID`,
                initialData: { ID: props.id },
                permissionObject: this.permissionObject,
                action: async data => {
                    let unblock;
                    try {
                        const payload = DataClass.postSubData(data, props);
                        if (payload) {
                            const result = await this.addSubData(
                                payload.namaIndo,
                                `Subspesialisasi`
                            );
                            if (result.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await spesialisasiRepo.postSubpspesialisasi(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                } else {
                                    closeModalFunc();
                                    this.emitFilterQuery();
                                    this.showPopUp(
                                        "Data Subspesialisasi berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        console.log(error, "error");
                        this.showErrorPopUp(error);
                    }
                },
                searchSub: async name => {
                    return await this.getSupsesialisasiDropdown(name);
                }
            };
            let { close } = ModalService.open(
                SpesialisasiAddDataModal,
                payload
            );
            closeModalFunc = close;
        },
        async spesialisasiAddModal() {
            let closeModalFunc;
            let payload = {
                permissionObject: this.permissionObject,
                action: async data => {
                    let unblock;
                    try {
                        const payload = DataClass.postSubspesialisasiData(data);
                        if (payload) {
                            const result = await this.addSubData(
                                payload.namaIndo
                            );
                            if (result.value) {
                                unblock = this.blockUi.blockModal();
                                const resp = await spesialisasiRepo.postDataSpesialisasi(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                } else {
                                    closeModalFunc();
                                    this.emitFilterQuery();
                                    this.showPopUp(
                                        "Data Spesialisasi berhasil ditambah",
                                        "success",
                                        "successImg"
                                    );
                                }
                            }
                        }
                    } catch (error) {
                        if (typeof unblock == "function") {
                            unblock();
                        }
                        console.log(error, "error");
                        this.showErrorPopUp(error);
                    }
                },
                searchSub: async name => {
                    return await this.getSupsesialisasiDropdown(name);
                }
            };
            let { close } = ModalService.open(
                SpesialisasiAddDataModal,
                payload
            );
            closeModalFunc = close;
        },
        async spesialisasiEditModal(props, isViewOnly) {
            let unblock;
            try {
                console.log(props, "props2");
                let closeModalFunc;
                unblock = this.blockUi.blockPage();
                const initialData = await spesialisasiRepo.getOneSpe(props.id);
                unblock();
                if (initialData.error) {
                    throw initialData;
                }
                let modalTitle = "Edit Spesialisasi";
                if (isViewOnly) {
                    modalTitle = "Detail Spesialisasi";
                }
                let payload = {
                    modalTitle,
                    initialData: initialData,
                    isViewOnly: isViewOnly,
                    permissionObject: this.permissionObject,
                    action: async data => {
                        let unblock;
                        try {
                            // unblock = this.blockUi.blockModal();
                            const payload = DataClass.putSubspesialisasiData(
                                data,
                                initialData.ID
                            );
                            if (payload) {
                                unblock = this.blockUi.blockModal();
                                const resp = await spesialisasiRepo.putSpe(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                }
                                this.emitFilterQuery();
                                closeModalFunc();
                                this.showPopUp(
                                    "Data Spesialisasi berhasil diubah",
                                    "success",
                                    "successImg"
                                );
                            }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            console.log(error, "error");
                            this.showErrorPopUp(error);
                        }
                    },
                    searchSub: async name => {
                        return await this.getSupsesialisasiDropdown(name);
                    }
                };
                let { close } = ModalService.open(
                    SpesialisasiAddDataModal,
                    payload
                );
                closeModalFunc = close;
            } catch (error) {
                console.log(error, "subspesialisasiEditModal error");
                this.showErrorPopUp(error);
            }
        },
        async subSpesialisasiEditModal(props, isViewOnly) {
            let unblock;
            try {
                console.log(props, "props");
                let closeModalFunc;
                unblock = this.blockUi.blockPage();
                const initialData = await spesialisasiRepo.getOneSubpspesialisasi(
                    props.id
                );
                unblock();
                if (initialData.error) {
                    throw initialData;
                }
                let modalTitle = "Edit Subspesialisasi";
                if (isViewOnly) {
                    modalTitle = "Detail Subspesialisasi";
                }
                let payload = {
                    modalTitle,
                    initialData: initialData,
                    idTitle: `Subspesialisasi ID`,
                    isViewOnly: isViewOnly,
                    permissionObject: this.permissionObject,
                    action: async data => {
                        let unblock;
                        try {
                            // unblock = this.blockUi.blockModal();
                            const payload = DataClass.putSubspesialisasiData(
                                data,
                                initialData.ID
                            );
                            if (payload) {
                                unblock = this.blockUi.blockModal();
                                const resp = await spesialisasiRepo.putSubpspesialisasi(
                                    payload
                                );
                                unblock();
                                if (resp.error) {
                                    throw resp;
                                }
                                this.emitFilterQuery();
                                closeModalFunc();
                                this.showPopUp(
                                    "Data Subspesialisasi berhasil diubah",
                                    "success",
                                    "successImg"
                                );
                            }
                        } catch (error) {
                            if (typeof unblock == "function") {
                                unblock();
                            }
                            console.log(error, "error");
                            this.showErrorPopUp(error);
                        }
                    },
                    searchSub: async name => {
                        return await this.getSupsesialisasiDropdown(name);
                    }
                };
                let { close } = ModalService.open(
                    SpesialisasiAddDataModal,
                    payload
                );
                closeModalFunc = close;
            } catch (error) {
                console.log(error, "subspesialisasiEditModal error");
                this.showErrorPopUp(error);
            }
        },
        confirmationDataValidate(data) {
            let namaDokters = [];
            let namaSubspesialisasi = [];
            if (data) {
                if (data.dokterSpesialisasis || data.dokterSubSpesialisasis) {
                    let dokterArray = [];
                    if (data.dokterSpesialisasis) {
                        dokterArray = data.dokterSpesialisasis;
                    } else if (data.dokterSubSpesialisasis) {
                        dokterArray = data.dokterSubSpesialisasis;
                    }

                    dokterArray.forEach(element => {
                        namaDokters.push(
                            `${element.dokter.ID} - ${element.dokter.nama}`
                        );
                    });
                }
                if (data.subSpesialisasis) {
                    data.subSpesialisasis.forEach(element => {
                        namaSubspesialisasi.push(element.namaIndo);
                    });
                }
            }
            return {
                namaDokters,
                namaSubspesialisasi
            };
        },
        async prevDelConfirmation({ id, namaIndonesia }) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning">
                        <p>${namaIndonesia}</p>
                        <p> ID: ${id} </p>
                     </div>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title:
                            "Apakah Anda yakin ingin menghapus spesialisasi berikut?",
                        html: html,
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, hapus data",
                        textCancel: "Tidak, batalkan"
                    },
                    "dangerImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async deleteConfirmationSpesialisasi(rowData) {
            let unblock;
            const { id, namaIndonesia } = rowData;
            // console.log(rowData, "rowData");
            try {
                const res = await this.prevDelConfirmation({
                    id,
                    namaIndonesia
                });
                if (res.value) {
                    unblock = this.blockUi.blockPage();
                    let methodStr = `delConfirmation`;
                    //delConfirmationDeleteSub
                    if (rowData.isChild) {
                        methodStr = `delConfirmationDeleteSub`;
                    }
                    const resp = await spesialisasiRepo[methodStr](id);
                    unblock();
                    if (resp) {
                        if (resp.error) {
                            // unblock();
                            throw resp;
                        }
                        // unblock();
                        const {
                            namaDokters,
                            namaSubspesialisasi
                        } = this.confirmationDataValidate(resp);

                        let html = `<div class="sub-delete">`;
                        html += `<div class="data-warning">
                        <p>${namaIndonesia}</p>
                        <p> ID: ${id} </p>
                        </div>`;
                        if (namaDokters.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Dokter terkait:</p> <div> ${namaDokters
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                         </div> </div>`;
                        }
                        if (namaSubspesialisasi.length > 0) {
                            html += `<div class="swal-dokter-container"> <p>Subspesialisasi terkait::</p> <div> ${namaSubspesialisasi
                                .map(el => {
                                    return `<p>${el}</p>`;
                                })
                                .join("")}
                        </div> </div>`;
                        }
                        html += `</div>`;
                        const result = await new SweetAlert().show(
                            {
                                title:
                                    `Apakah Anda yakin ingin menghapus subspesialisasi berikut?`,
                                html: html,
                                allowOutsideClick: false,
                                reverseButtons: true,
                                showCancelButton: true,
                                textConfirm: "Ya, hapus data",
                                textCancel: "Tidak, batalkan"
                            },
                            "dangerImg"
                        );

                        if (result.value) {
                            //
                            let secondMethodStr = `delSpesialisasi`;
                            if (rowData.isChild) {
                                secondMethodStr = `delSubspesialisasi`;
                            }
                            unblock = this.blockUi.blockPage();
                            const del = await spesialisasiRepo[secondMethodStr](
                                resp
                            );
                            if (del.error) {
                                throw del;
                            }
                            unblock();
                            this.showPopUp(
                                "Data Spesialisasi berhasil dihapus",
                                "success",
                                "successImg"
                            );
                            this.emitFilterQuery();
                        }
                    } else {
                        this.showPopUp(
                            "Data Spesialisasi berhasil dihapus",
                            "success",
                            "successImg"
                        );
                        this.emitFilterQuery();
                    }
                }
            } catch (error) {
                if (typeof unblock == "function") {
                    unblock();
                }
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async addSubData(nama, type) {
            try {
                let html = `<div class="sub-delete">`;
                html += `<div class="data-warning"  style="background: #F3F8FF;">
                        <p>${nama}</p>
                     </div>`;
                html += `<p class="sub-delete__text">Data ${
                    type ? type : "Spesialisasi"
                } akan ditambah</p>`;
                html += `</div>`;
                const result = await new SweetAlert().show(
                    {
                        title: `Apakah Anda yakin ingin menambah Data ${
                            type ? type : "Spesialisasi"
                        } berikut?`,
                        html: html,
                        // text: "Data tarif akan terhapus secara permanen",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        showCancelButton: true,
                        textConfirm: "Ya, Tambah",
                        textCancel: "Tidak, Batalkan"
                    },
                    "warningImg"
                );
                return result;
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async reindexData() {
            try {
                // const resp = await spesialisasiRepo.reIndexData();
                // const resp = await spesialisasiRepo.reIndexDataList();
                const resp = await Promise.all([
                    // spesialisasiRepo.reIndexData(),
                    spesialisasiRepo.reIndexDataList()
                ]);
                if (Array.isArray(resp)) {
                    resp.forEach(e => {
                        if (e.error) {
                            throw e;
                        }
                    });
                }

                // if (resp.error) {
                //     throw resp;
                // }
            } catch (error) {
                console.log(error);
                this.showErrorPopUp(error);
            }
        },
        async syncData() {
            this.isSyncDataLoading = true;
            await this.reindexData();
            this.isSyncDataLoading = false;
        },
        routeQueryToFilterList() {
            let filterQueryNames = [
                "id",
                "publishSpesialisasi",
                "namaIndonesia",
                "namaAwamIndonesia",
                "namaAwamInggris",
                "namaInggris"
            ];
            filterQueryNames.forEach(name => {
                if (this.$route.query[name]) {
                    this.filterList[name] = this.$route.query[name];
                }
            });
        }
    },
    created() {
        this.initPermissionCheck();
        this.routeQueryToFilterList();
    }
};
</script>

<style></style>
