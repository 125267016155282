<template>
    <div>
        <BtnAction
            style="justify-content: center;margin-bottom: 0.8rem;"
            :rowData="rowData"
            :buttonMethod="buttonMethod"
        >
            <slot name="item" slot="dropdown-item"></slot>
        </BtnAction>
        <div
            class="action-link"
            style="margin-bottom: 0.8rem;"
            v-for="(sub, index) in rowData.subspesialisasi"
            :key="index"
        >
            <a style="display: flex;align-items: center;"
                >Lihat <ExternalLinkIcon style="margin-left: 8px;"
            /></a>
        </div>
    </div>
</template>

<script>
import BtnAction from "./BtnAction.vue";
import ExternalLinkIcon from "../../_icons/ExternalLinkIcon.vue";
export default {
    name: "BtnActionData",
    components: {
        BtnAction,
        ExternalLinkIcon
    },
    props: {
        rowData: {
            type: Object
        },
        buttonMethod: {
            type: Object
        }
    }
};
</script>

<style></style>
