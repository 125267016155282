<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div v-if="formData.id" class="form-group">
                    <label class=" col-form-label form-label">{{
                        idTitle
                    }}</label>
                    <div>
                        <span style="margin-left: 12px;">{{
                            formData.id | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama ID<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control text-input"
                            v-model="formData.namaIndonesia"
                            placeholder="Tulis Nama Indonesia"
                            v-validate="'required'"
                            data-vv-as="Nama Indonesia"
                            name="Indonesia"
                            :class="{ 'is-invalid': errors.has('Indonesia') }"
                        />
                        <div
                            v-show="errors.first('Indonesia')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("Indonesia") }}
                        </div>
                        <!-- <VueInputText
                            :placeholder="'Tulis Nama Indonesia'"
                            :validation="'required'"
                            :validationName="'Nama Indonesia'"
                            v-model="formData.namaIndonesia"
                            :scope="'nama-indonesia'"
                        >
                        </VueInputText> -->
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            formData.namaIndonesia | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama EN<span style="color:red;">*</span></label
                    >
                    <div v-if="!isViewOnly">
                        <input
                            type="text"
                            class="form-control text-input"
                            v-model="formData.namaInggris"
                            placeholder="Tulis Nama Inggris"
                            v-validate="'required'"
                            data-vv-as="Nama Inggris"
                            name="Inggris"
                            :class="{ 'is-invalid': errors.has('Inggris') }"
                        />
                        <div
                            v-show="errors.first('Inggris')"
                            class="invalid-feedback"
                        >
                            {{ errors.first("Inggris") }}
                        </div>
                        <!-- <VueInputText
                            :placeholder="'Tulis Nama Inggris'"
                            :validation="'required'"
                            :validationName="'Nama Inggris'"
                            v-model="formData.namaInggris"
                            :scope="'nama-inggris'"
                        >
                        </VueInputText> -->
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            formData.namaInggris | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam ID</label
                    >
                    <div v-if="!isViewOnly">
                        <VueInputText
                            class="text-input"
                            :placeholder="'Tulis Nama Awam Indonesia'"
                            :validationName="'Nama Awam Indonesia'"
                            v-model="formData.namaAwamIndonesia"
                        >
                        </VueInputText>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            formData.namaAwamIndonesia | strip
                        }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam EN</label
                    >
                    <div v-if="!isViewOnly">
                        <VueInputText
                            class="text-input"
                            :placeholder="'Tulis Nama Awam Inggris'"
                            :validationName="'Nama Awam Inggris'"
                            v-model="formData.namaAwamInggris"
                            :scope="'nama-awam-inggris'"
                        >
                        </VueInputText>
                    </div>
                    <div v-else>
                        <span style="margin-left: 12px;">{{
                            formData.namaAwamInggris | strip
                        }}</span>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Subspesialisasi</label
                    >
                    <div v-if="!isViewOnly">
                        <Multiselect
                            class="holiday-multiselect text-input"
                            track-by="id"
                            label="namaIndo"
                            :multiple="true"
                            :options="spesialisasiOptions"
                            :allow-empty="true"
                            :close-on-select="false"
                            :searchable="true"
                            v-model="formData.subspesialisasi"
                            :loading="spesialisasiOptionsLoading"
                            placeholder="Cari Subspesialisasi terkait"
                            @search-change="searchSub"
                        >
                            <template
                                slot="selection"
                                slot-scope="{ values, isOpen }"
                                ><span
                                    style="background: #fafafa;"
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                    >{{ values.length }} Spesialisasi
                                    Terpilih</span
                                ></template
                            >
                        </Multiselect>
                        <div style="margin-top: 15px;">
                            <div
                                v-for="(sub,
                                index) in formData.subspesialisasi"
                                :key="index"
                                class="selected-multi"
                            >
                                <div class="text-multi">
                                    <button
                                        @click.prevent=""
                                        class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                        :style="
                                            formData.subspesialisasi[
                                                index
                                            ].isPublish
                                                ? 'background:#EBF6F4 !important;margin-right:12px;cursor: default;'
                                                : 'background:#FFF2F2 !important;margin-right:12px;cursor: default;'
                                        "
                                    >
                                        <EyeFillIcon
                                            style="width:18px;"
                                            v-if="
                                                formData
                                                    .subspesialisasi[index]
                                                    .isPublish
                                            "
                                        />
                                        <EyeIconSlashed
                                            style="width:18px;"
                                            v-else
                                        />
                                    </button>
                                    <p
                                        class="text-multi__number"
                                        style="width:10px;"
                                    >
                                        {{ index + 1 }}.
                                    </p>
                                    &nbsp;
                                    <p>{{ sub.namaIndo | strip }}</p>
                                </div>
                                <div class="button-multi">
                                    <button
                                        style="margin-left:24px;"
                                        @click.prevent="deleteSelected(index)"
                                        class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                        title="Hapus"
                                    >
                                        <TrashIcon :strokeColor="'#000000'" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div
                            v-for="(sub,
                            index) in formData.subspesialisasi"
                            :key="index"
                            class="selected-multi"
                        >
                            <div class="text-multi">
                                <button
                                    @click.prevent=""
                                    class="btn btn-sm btn-clean btn-icon btn-icon-md button-action"
                                    :style="
                                        formData.subspesialisasi[index]
                                            .isPublish
                                            ? 'background:#EBF6F4 !important;'
                                            : 'background:#FFF2F2 !important;'
                                    "
                                    title="Hapus"
                                >
                                    <EyeFillIcon
                                        style="width:18px;"
                                        v-if="
                                            formData.subspesialisasi[
                                                index
                                            ].isPublish
                                        "
                                    />
                                    <EyeIconSlashed
                                        style="width:18px;"
                                        v-else
                                    />
                                </button>
                                <p
                                    class="text-multi__number"
                                    style="width:10px;"
                                >
                                    {{ index + 1 }}.
                                </p>
                                &nbsp;
                                <p>{{ sub.namaIndo | strip }}</p>
                            </div>
                           
                        </div>
                    </div>
                </div> -->
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer footer-doctor">
            <div v-if="!isViewOnly">
                <span>
                    <EyeFillIcon
                        style="width:18px;"
                        v-if="formData.isPublishProfile"
                    />
                    <EyeIconSlashed style="width:18px;" v-else />Publish
                    {{
                        modalTitle == `Tambah Subspesialisasi` ||
                        modalTitle == `Edit Subspesialisasi`
                            ? `Subspesialisasi`
                            : `Spesialisasi`
                    }}
                </span>
                <label style="margin-top: 7px;" class="switch-publish">
                    <input
                        v-model="formData.isPublishProfile"
                        type="checkbox"
                    />
                    <span class="slider round"></span>
                </label>
            </div>
            <div v-else>
                <PublishChips
                    :isPublished="formData.isPublishProfile"
                    :textString="
                        formData.isPublishProfile ? 'published' : 'unpublished'
                    "
                />
            </div>
            <div v-if="!isViewOnly" class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="validateData"
                    class="btn btn-primary custom-modal-button"
                >
                    Simpan
                </button>
            </div>
            <div v-else>
                <button
                    v-if="(permissionCheck('isCanEditSpesialisasi') && modalTitle == 'Detail Spesialisasi') || (permissionCheck('isCanEditSubspesialisasi') && modalTitle == 'Detail Subspesialisasi')" 
                    @click.prevent="editModal"
                    class="btn btn-primary custom-modal-button"
                >
                    Edit
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
// import PlaceHolderImage from "../../assets/placeholder.png";

import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import VueInputText from "../../../_general/VueInputText.vue";

import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";
import EyeFillIcon from "../../../_icons/EyeFillIcon.vue";
import TrashIcon from "../../../_icons/TrashIcon.vue";

import PublishChips from "../../../_base/PublishChips.vue";

import Multiselect from "vue-multiselect";
import VeeValidate from "vee-validate";
VeeValidate.setMode("passive");

export default {
    name: "SpesialisasiAddDataModal",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        PublishChips,
        Multiselect,
        EyeIconSlashed,
        EyeFillIcon,
        TrashIcon,
        VueInputText
    },
    mixins: [ModalMixin],
    provide() {
        return {
            $validator: this.$validator
        };
    },
    data() {
        var vx = this;
        return {
            // imagePlaceHolder: PlaceHolderImage,
            spesialisasiOptions: [
                // { id: 200, namaIndo: "Spesialisasi 1", isPublish: false },
                // { id: 201, namaIndo: "Spesialisasi 2", isPublish: false },
                // { id: 202, namaIndo: "Spesialisasi 3", isPublish: false },
                // { id: 203, namaIndo: "Spesialisasi 4", isPublish: false }
            ],
            spesialisasiOptionsLoading: false,
            idTitle: "ID",
            formData: {
                namaIndonesia: null,
                namaInggris: null,
                namaAwamIndonesia: null,
                namaAwamInggris: null,
                isPublishProfile: true,
                subspesialisasi: []
            },
            isViewOnly: false,
            modalTitle: "Tambah Spesialisasi",
            searchSub: _.debounce(async query => {
                try {
                    if (query) {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSub == "function"
                            ) {
                                vx.spesialisasiOptionsLoading = true;
                                const resp = await vx.componentData.searchSub(
                                    query
                                );
                                vx.spesialisasiOptionsLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    } else {
                        if (vx.componentData) {
                            if (
                                typeof vx.componentData.searchSub == "function"
                            ) {
                                vx.spesialisasiOptionsLoading = true;
                                const resp = await vx.componentData.searchSub();
                                vx.spesialisasiOptionsLoading = false;
                                vx.spesialisasiOptions = resp;
                            }
                        }
                    }
                } catch (error) {
                    vx.spesialisasiOptionsLoading = false;
                    console.log(error);
                }
            }, 500)
        };
    },
    watch: {
        // "formData.subspesialisasi": {
        //     handler: function(newVal, oldVal) {
        //         console.log(newVal, oldVal, "value");
        //         newVal.forEach(val => {
        //             if (fal){}
        //         })
        //         // this.$emit("update:formData", this.formData);
        //     },
        //     deep: true
        // }
    },
    filters: {
        strip(text) {
            return text ? text : " - ";
        }
    },
    methods: {
        validateData() {
            this.$validator.validateAll().then(result => {
                console.log(result);
                if (result) {
                    this.sendData(this.formData);
                }
            });
        },
        sendData(data) {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(data);
                }
            }
        },
        editModal() {
            this.isViewOnly = false;
            this.modalTitle = "Edit Spesialisasi";
        },
        deleteSelected(index) {
            this.formData.subspesialisasi.splice(index, 1);
        },
        selectSelected(index) {
            this.formData.subspesialisasi[index].isPublish = !this.formData
                .subspesialisasi[index].isPublish;
        },
        permissionCheck(permission){
            let permissionBool = true
            if (this.componentData) {
                if(this.componentData.permissionObject){
                    if (!this.componentData.permissionObject[permission]) {
                        permissionBool = this.componentData.permissionObject[permission]
                    }
                }
            }
            return permissionBool
        }
    },
    created() {
        // this.searchSub();
        // console.log(this.componentData, "componentDataa");

        if (this.componentData) {
            if (this.componentData.initialData) {
                if (this.componentData.initialData.ID) {
                    this.formData.id = this.componentData.initialData.ID;
                }
                if (this.componentData.initialData.namaIndo) {
                    this.formData.namaIndonesia = this.componentData.initialData.namaIndo;
                }
                if (this.componentData.initialData.namaAwam) {
                    this.formData.namaAwamIndonesia = this.componentData.initialData.namaAwam;
                }
                if (this.componentData.initialData.namaAwamEng) {
                    this.formData.namaAwamInggris = this.componentData.initialData.namaAwamEng;
                }
                if (this.componentData.initialData.namaEng) {
                    this.formData.namaInggris = this.componentData.initialData.namaEng;
                }
                if (
                    typeof this.componentData.initialData.isPublish == "boolean"
                ) {
                    this.formData.isPublishProfile = this.componentData.initialData.isPublish;
                }
                if (this.componentData.initialData.subSpesialisasis) {
                    this.formData.subspesialisasi = this.componentData.initialData.subSpesialisasis;
                }
            }
            if (this.componentData.isViewOnly) {
                this.isViewOnly = this.componentData.isViewOnly;
            }
            if (this.componentData.modalTitle) {
                this.modalTitle = this.componentData.modalTitle;
            }
            if (this.componentData.idTitle) {
                this.idTitle = this.componentData.idTitle;
            }
        }
    }
};
</script>

<style></style>
