<template>
    <Modal class="custom-modal doctor-filter">
        <ModalHeader class="custom-header"
            ><h3>{{ modalTitle }}</h3>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="dismiss(idComponent)"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </ModalHeader>
        <ModalBody>
            <form class="form-modal">
                <div class="form-group">
                    <label class=" col-form-label form-label">ID</label>
                    <div>
                        <input
                            v-model="filterData.id"
                            class="text-input"
                            type="text"
                            placeholder="Cari ID Spesialisasi"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Nama ID</label>
                    <div>
                        <input
                            class="text-input"
                            v-model="filterData.namaIndonesia"
                            type="text"
                            placeholder="Cari Nama Indonesia"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam ID</label
                    >
                    <div>
                        <input
                            v-model="filterData.namaAwamIndonesia"
                            class="text-input"
                            type="text"
                            placeholder="Cari Nama Awam Indonesia"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label">Nama EN</label>
                    <div>
                        <input
                            v-model="filterData.namaInggris"
                            class="text-input"
                            type="text"
                            placeholder="Cari Nama Inggris"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label class=" col-form-label form-label"
                        >Nama Awam EN</label
                    >
                    <div>
                        <input
                            v-model="filterData.namaAwamInggris"
                            class="text-input"
                            type="text"
                            placeholder="Cari Nama Awam Inggris"
                        />
                    </div>
                </div>
                <div class="form-divider"></div>
                <div class="from-group">
                    <label class=" col-form-label form-label"
                        >Publish Spesialisasi</label
                    >
                    <div>
                        <div class="switch-toggle switch-3 switch-candy">
                            <input
                                id="on-publish"
                                name="publish"
                                type="radio"
                                checked=""
                                :value="'publish'"
                                v-model="filterData.publishSpesialisasi"
                            />
                            <label for="on-publish" onclick="">
                                <div style="width:24px;height:18px;">
                                    <EyeIcon
                                        :strokeColor="
                                            filterData.publishSpesialisasi ===
                                            'publish'
                                                ? '#ffffff'
                                                : `#23415B`
                                        "
                                        :fillColor="
                                            filterData.publishSpesialisasi ===
                                            'publish'
                                                ? '#23415B'
                                                : `#ffffff`
                                        "
                                    />
                                </div>
                            </label>

                            <input
                                id="na-publish"
                                name="napublish"
                                type="radio"
                                :value="null"
                                v-model="filterData.publishSpesialisasi"
                            />
                            <label for="na-publish" class="disabled" onclick="">
                                <div
                                    class="na-switch"
                                    :style="
                                        filterData.publishSpesialisasi === null
                                            ? ''
                                            : 'color:#23415B'
                                    "
                                >
                                    ALL
                                </div>
                            </label>

                            <input
                                id="off-unpublish"
                                name="unpublish"
                                type="radio"
                                :value="'unpublish'"
                                v-model="filterData.publishSpesialisasi"
                            />
                            <label for="off-unpublish" onclick="">
                                <div style="width:24px;height:18px;">
                                    <EyeIconSlashed
                                        :strokeColor="
                                            filterData.publishSpesialisasi ===
                                            'unpublish'
                                                ? '#ffffff'
                                                : `#23415B`
                                        "
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </ModalBody>

        <ModalFooter class="custom-footer">
            <div class="d-flex">
                <a
                    class="link-batal"
                    style="margin-right: 40px;line-height: 40px;"
                    @click.prevent="dismiss(idComponent)"
                    >Batal</a
                >
                <button
                    @click.prevent="saveFilter"
                    class="btn btn-primary custom-modal-button"
                >
                    Terapkan
                </button>
            </div>
        </ModalFooter>
    </Modal>
</template>

<script>
import Modal from "../../../_modal/Modal.vue";
import ModalHeader from "../../../_modal/ModalHeader.vue";
import ModalBody from "../../../_modal/ModalBody.vue";
import ModalFooter from "../../../_modal/ModalFooter.vue";
import ModalMixin from "../../../../mixins/ModalMixins";

import EyeIcon from "../../../_icons/EyeIcon.vue";
import EyeIconSlashed from "../../../_icons/EyeIconSlashed.vue";

import Multiselect from "vue-multiselect";
export default {
    name: "SpesialisasikModalFilter",
    components: {
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter,
        EyeIcon,
        EyeIconSlashed,

        Multiselect
    },
    mixins: [ModalMixin],
    data() {
        return {
            modalTitle: "Filter Spesialisasi",
            filterData: {
                id: null,
                namaIndonesia: null,
                namaAwamIndonesia: null,
                namaInggris: null,
                namaAwamInggris: null,
                publishSpesialisasi: null
            }
        };
    },
    methods: {
        saveFilter() {
            if (this.componentData) {
                if (typeof this.componentData.action == "function") {
                    this.componentData.action(this.filterData);
                }
            }
        }
    },
    created() {
        if (this.componentData) {
            if (this.componentData.initialData) {
                if (this.componentData.initialData.id) {
                    this.filterData.id = this.componentData.initialData.id;
                }
                if (this.componentData.initialData.namaIndonesia) {
                    this.filterData.namaIndonesia = this.componentData.initialData.namaIndonesia;
                }
                if (this.componentData.initialData.namaAwamIndonesia) {
                    this.filterData.namaAwamIndonesia = this.componentData.initialData.namaAwamIndonesia;
                }
                if (this.componentData.initialData.namaInggris) {
                    this.filterData.namaInggris = this.componentData.initialData.namaInggris;
                }
                if (this.componentData.initialData.namaAwamInggris) {
                    this.filterData.namaAwamInggris = this.componentData.initialData.namaAwamInggris;
                }
                if (this.componentData.initialData.publishSpesialisasi) {
                    this.filterData.publishSpesialisasi = this.componentData.initialData.publishSpesialisasi;
                }
                // if (this.componentData.initialData.klinikType) {
                //     this.filterData.klinikType = this.componentData.initialData.klinikType;
                // }
            }
        }
    }
};
</script>

<style></style>
